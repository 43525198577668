import { Controller } from "stimulus"


export default class extends Controller { 
  initialize () {
    this.scroll = this.scroll.bind(this)
  }

  connect () {
    this.element.addEventListener('click', this.scroll)
  }

  scroll(event) {
    const id = this.element.hash.replace(/^#/, '')
    const target = document.getElementById(id)
    if ( target && target.scrollIntoView) { 
      event.preventDefault()
      target.scrollIntoView({behavior: "smooth"})
      setTimeout(() => {
        window.location.hash = id; 
      }, 250)
    } else { 
      console.log("No target or unsupported", target, id )
    }
  }

}